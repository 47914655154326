import { defineStore } from 'pinia'
import axios from 'axios'
import router from '@/router'
import type { User } from '@/types/user.type'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    token: localStorage.getItem('token') || null,
    user: JSON.parse(localStorage.getItem('user') || 'null') as User | null,
    count: JSON.parse(localStorage.getItem('count') || '0') as number,
  }),
  actions: {
    unreadInboxCount() {
      return this.count
    },
    userAccount(): User | null {
      return this.user
    },
    isAuthenticated() {
      return !!this.token
    },
    authenticate(token: string) {
      this.token = token
      localStorage.setItem('token', token)  // Store token in localStorage
      router.push('/') // Redirect after login
    },
    setUser(user: User) {
      this.user = user
      localStorage.setItem('user', JSON.stringify(user))  // Store user in localStorage
    },
    setUnreadInboxCount(count: number) {
      this.count = count
      localStorage.setItem('count', JSON.stringify(count))  // Store count in localStorage
    },
    async logout() {
      await axios.post('/api/v1/auth/logout')
      this.token = null
      this.user = null
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      router.push('/') // Redirect after logout
    },
    async getUser() {
      const response = await axios.get('/api/v1/users/me')
      this.setUser(response.data.user)
      this.setUnreadInboxCount(response.data.unread_inbox_count)
      return this.user
    }
  },
  persist: {
    key: 'auth',
    storage: window.localStorage,
  },
})
